import React, { useState, useEffect, useRef, useCallback, FormEvent } from "react";
import { defaultColors, styled } from "../../../stitches.config";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg?react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg?react";

import { h4 } from "../../commonStyles/Fonts";

import Logo from "../../atoms/Logo";

import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  toggleSearch: () => void;
  fixed?: boolean;
}

const SearchBar: React.FC<Props> = ({ toggleSearch, fixed }) => {

  const searchInput = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChange = useCallback((e: any) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSearch = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue != null && searchValue.trim() !== "") {
      toggleSearch();
      navigate(`/search?q=${searchValue}`);
    }
  }, [searchValue, navigate, toggleSearch]);

  useEffect(() => {
    searchInput.current !== null && searchInput.current.focus();
  }, [searchInput]);

  useEffect(() => {
    if (searchInput.current && searchValue === null) {
      searchInput.current.value = "";
      searchInput.current.focus();
    }
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  return (
    <>
      <MobileSearch>
        <MobileSearchHeader>
          <Logo
            css={{
              width: 200,
              height: 52,
              "& svg": {
                height: 18,
              },
            }}
            type="dark"
            className="mobile-search-logo"
          />
          <CloseIcon onClick={toggleSearch} />
        </MobileSearchHeader>
        <MobileSearchField onSubmit={handleSearch}>
          <SearchIcon />
          <SearchInput
            className={h4()}
            placeholder="Szukaj"
            onChange={handleChange}
          />
        </MobileSearchField>
      </MobileSearch>
      <SearchBarWrapper onSubmit={handleSearch}>
        <button type="submit">
          <SearchIcon />
        </button>
        <SearchInput
          className={h4()}
          placeholder="Szukaj"
          onChange={handleChange}
          ref={searchInput}
        />
        {searchValue && (
          <ClearButton onClick={() => setSearchValue(null)}>
            Wyczyść
          </ClearButton>
        )}
        <button onClick={toggleSearch}>
          <CloseIcon />
        </button>
      </SearchBarWrapper>
    </>
  );
};

const ClearButton = styled("button", {
  position: "absolute",
  right: "70px",
  top: "calc(50% - 3px)",
  transform: "translateY(-50%)",
  color: defaultColors.grayscale75,
  "&:hover": {
    color: defaultColors.grayscale0,
  },
});

const SearchBarWrapper = styled("form", {
  height: "100%",
  position: "relative",
  backgroundColor: "$darkmodeBg",
  width: "100%",
  borderLeft: "1px solid rgba(255, 255, 255, .1)",
  gridTemplateColumns: "60px auto 66px",
  button: {
    background: "none",
    margin: "0px",
    borderWidth: "0px",
    svg: {
      width: "16px",
      height: "16px",
    },
  },
  display: "none",
  "@bp4": {
    display: "grid",
  },
});

const SearchInput = styled("input", {
  color: defaultColors.grayscale0,
  background: "none",
  border: "none",
  "&:placeholder": {
    color: defaultColors.grayscale75,
  },
});

const MobileSearch = styled("div", {
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000000,
  background: defaultColors.grayscale100,
  "@bp4": {
    display: "none",
  },
});

const MobileSearchHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "20px",
  "& .mobile-search-logo": {
    marginLeft: "20px",
    justifyContent: "flex-start",
    "& svg": {
      width: "100%",
    }
  },
});

const MobileSearchField = styled("form", {
  backgroundColor: "$darkmodeBg",
  padding: "20px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

export default SearchBar;
